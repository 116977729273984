const artistsData = {
  amir: {
    name: "Amir",
    url: "amir",
    formID: "41589",
    startDate: "",
    pathName: "amir",
    dota: "1 hoodie AMIR + 2 places de concert dans la ville de ton choix avec une rencontre à la clé + un vinyle dédicacé",
    dotaTwi:
      "1 hoodie AMIR + 2 places de concert avec une rencontre à la clé et d'autres cadeaux !",
    categories: [
      {
        name: "Artiste masculin francophone de l'année",
        link: "https://Amir.lnk.to/NMA2024",
        id: "amir-artiste-masculin-francophone",
      },
    ],
    picture: "/assets/img/amir-desktop.png",
    pictureMobile: "/assets/img/amir-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/amir",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez l'album « C amir » de Amir",
        text: "Partage l'album « C amir » sur ton réseau social favori",
        url: "https://amir.lnk.to/camir!NMA",
        points: 20,
      },
    ],
  },
  davidguetta: {
    name: "David Guetta",
    url: "davidguetta",
    formID: "41723",
    startDate: "",
    pathName: "davidguetta",
    dota: "un disque d'or inédit avec tous ses derniers succès à ton nom !",
    dotaTwi: "un disque d'or inédit avec tous ses derniers succès à ton nom !",
    categories: [
      {
        name: "Artiste masculin francophone de l'année",
        link: "https://DavidGuetta.lnk.to/artisteMasculin",
        id: "davidguetta-artiste-masculin-francophone",
      },
      {
        name: "Collab'/Duo de l'année",
        link: "https://DavidGuetta.lnk.to/duoInter",
        id: "davidguetta-collab-duo-international",
      },
      {
        name: "DJ de l'année",
        link: "https://davidguetta.lnk.to/djdelannee",
        id: "davidguetta-dj",
      },
    ],
    picture: "/assets/img/guetta-mobile.png",
    pictureMobile: "/assets/img/guetta-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/davidguetta",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez le titre « Forever Young »",
        text: "Partage le titre « Forever Young » sur ton réseau social favori",
        url: "https://David-Guetta.lnk.to/ForeverYoung!OPNMA",
        points: 20,
      },
    ],
  },
};

export default artistsData;
