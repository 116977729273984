import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import artistsData from "../../data/artistsData";
import useIsSubscribed from "../../hooks/useIsSubscribed";
import useLocalStorage from "../../hooks/useLS";
import Artist from "../../pages/Artist";
import UnloggedArtist from "../../pages/UnloggedArtist";
import data from "../../data/data.js";

const ArtistLogger = ({ getUserData }) => {
  const location = useLocation();
  const navigate = useNavigate("/");
  const [user, setUser] = useLocalStorage("NMA2024user", null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [artist, setArtist] = useState(
    Object.values(artistsData).find(
      (artist) => artist.pathName === location.pathname.slice(1)
    )
  );

  const checkInscription = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}suscribed/${artist.pathName}/${user}`
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setIsSubscribed(response);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (data[0][location.pathname.slice(1)]) {
      if (user) {
        checkInscription();
      }
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <>
      {/* {isSubscribed} */}
      {/* {isSubscribed ? (
        <Artist setIsSubscribed={setIsSubscribed} getUserData={getUserData} />
      ) : (
        <UnloggedArtist
          getUserData={getUserData}
          setIsSubscribed={setIsSubscribed}
        />
      )} */}
      <UnloggedArtist getUserData={getUserData} setIsSubscribed={setIsSubscribed} />
      {/* <Artist setIsSubscribed={setIsSubscribed} getUserData={getUserData} /> */}
    </>
  );
};

export default ArtistLogger;
