const users = [
  {
    amir: {
      inscrit: false,
      id: "amir",
      name: "Amir",
      totalPoints: 0,
      votes: [
        {
          id: "amir-artiste-masculin-francophone",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
    davidguetta: {
      inscrit: false,
      id: "davidguetta",
      name: "David Guetta",
      totalPoints: 0,
      votes: [
        {
          id: "davidguetta-artiste-masculin-francophone",
          dates: [],
          points: 0,
        },
        {
          id: "davidguetta-collab-duo-international",
          dates: [],
          points: 0,
        },
        {
          id: "davidguetta-dj",
          dates: [],
          points: 0,
        },
      ],
      activations: {
        webapp: {
          points: 0,
          actions: [],
          isDone: false,
        },
        clip: {
          points: 0,
          actions: [],
          isDone: false,
        },
      },
    },
  },
];

export default users;
