import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.scss";
import useLocalStorage from "./hooks/useLS";
import Artists from "./pages/Artists";
import ArtistLogger from "./components/artistLogger";

function App() {
  const [user, setUser] = useLocalStorage("NMA2024user", null);

  const getUserData = async (_user) => {
    return await fetch(`${process.env.REACT_APP_BASE_URL}connection/${_user}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.Item) {
          return response;
        } else {
          return null;
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/:artistId"
          element={<ArtistLogger getUserData={getUserData} />}
        />
        <Route path="/" element={<Artists />} />
      </Routes>
    </div>
  );
}

export default App;
