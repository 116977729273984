import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import artistsData from "../../data/artistsData.js";
import data from '../../data/data.js'
import LogoWarner from '../../assets/img/logowarner.svg'
import LogoNMA from '../../assets/img/mainLogo.webp'
import useLocalStorage from "../../hooks/useLS.js";
import useScript from "../../hooks/useScript.js";
import logoSpotify from '../../assets/img/spotify.svg'
import closeIcon from '../../assets/img/close.svg'
import InApp from 'detect-inapp';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import './index.scss'

const Inscription = ({ artist, isConnectPage, setIsOpenInscription, setIsSubscribed, getUserData }) => {
  const formulaireRef = useRef(null)
  const [isInapp, setIsInapp] = useState(new InApp(navigator.userAgent || navigator.vendor || window.opera))

  const params = useParams()
  const [user, setUser] = useLocalStorage("NMA2024user", null);
  const [error, setError] = useState('')
  const [showSpotify, setShowSpotify] = useState(true)

  const [state, setState] = useState({
    username: '',
    email: '',
    login: isConnectPage,
    isOpenRegister: false
  })

  const [formData, setFormData] = useState({
      nom: "",
      prenom: "",
      email: "",
      pseudo: "",
      phone: "",
      city: "",
      birthDate: "",
      reglement: false,
      optin: false,
      fbId: ""
  })

  const status = useScript(
    `https://wct.live/app/sdk/v2/platform.js?campaign=${artist.formID}`
  );

  useEffect(() => {
    if (status === "ready") {
      new window.WMGConnect(".wmg-button", {
        opt_in_thank_you_enabled: false,
        opt_in_form_target: '#formulaire'
    })
    .setCallback(function(connect_data){
          if (connect_data?.service === "spotify") {
            setShowSpotify(false)
            
            document.getElementById('wmgsdk-first_name').value = connect_data.user.name
            document.getElementById('wmgsdk-email').value = connect_data.user.email
          } else {
            window._satellite.track("email sign-up")	
            signup()
          }
      })

      lockSubmitBtn()

      document.getElementById('wmgsdk-city')?.addEventListener('input', () => {
        checkSubscribeForm()
      })

      document.getElementById('wmgsdk-mobile')?.addEventListener('input', () => {
        checkSubscribeForm()
      })

      document.getElementById('wmgsdk-email')?.addEventListener('input', () => {
        checkSubscribeForm()
      })
    }
  }, [status, state.login])

  const login = async (response) => {
    const __email = base64_encode(formData.email) || base64_encode(document.getElementById('wmgsdk-email').value)

    let resUser = response != undefined ? response : await getUserData(__email)

    if (resUser?.Item) {
      resUser = resUser.Item
    }

    if (resUser !== null) {
      if (resUser.mail) {
        if (__email === base64_encode(resUser.mail)) {
          if (resUser[artist.pathName].inscrit) {
            setUser(__email)
            setIsOpenInscription(false)
            setIsSubscribed(true)
          } else {
            setError("L'utilisateur n'est pas inscrit pour cet artiste")
          }
        } else {
          setError("L'utilisateur et le mail ne coincident pas")
        }
      } else {
        setUser(__email)
        setIsOpenInscription(false)
        setIsSubscribed(true)
      }
    } else {
      setError("L'utilisateur n'existe pas")
    }
  }

  const signup = (e) => {  
    const body = {
      ...data[0],
      mail: document.getElementById('wmgsdk-email').value,
      lastArtist: params.artistId
    }

    body[params.artistId].inscrit = true

    fetch(`${process.env.REACT_APP_BASE_URL}add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then((response) => response.json())
    .then((response) => login(response))
    .catch((err) => {})
  }

  const lockSubmitBtn = () => {
    document.querySelector('.opt-in-button-continue button').disabled = true 
  }

  const unlockSubmitBtn = () => {
    document.querySelector('.opt-in-button-continue button').disabled = false 
  }

  const checkSubscribeForm = () => {
    if (checkPhone() && checkEmail()) {
      unlockSubmitBtn()
      return true
    } else {
      lockSubmitBtn()
      return false
    }
  }

  const checkPhone = () => {
    const mobileInput = document.getElementById('wmgsdk-mobile')
    const regex = new RegExp(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g)
    if (regex.test(mobileInput.value)) {
      mobileInput.classList.remove('error')
      return true
    } else {
      mobileInput.classList.add('error')
      return false
    }
  }

  const checkEmail = () => {
    const emailInput = document.getElementById('wmgsdk-email')
    const regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

    if (regex.test(emailInput.value)) {
      emailInput.classList.remove('error')
      return true // test si dispo en base
    } else {
      emailInput.classList.add('error')
      return false
    }
  }
  
  const openForm = () => setState(prevState => ({ ...prevState, isOpenRegister: true, login: false }))
  const openLogin = () => setState(prevState => ({ ...prevState, isOpenRegister: true, login: true }))
  const setFormInput = (e, name) => {
      e.target.classList.remove('error')
      setFormData(prevState => ({ ...prevState, [name]: e.target.value }))
  }

  useEffect(() => {
     if (state.login === true && artist?.name) {
      window.digitalData={									
        settings:{								
          reportSuites:"wmg,wmgfr"							
        },								
        page:{								
          pageInfo:{							
            pageName:`Warner Music France:NRJ Awards 2023 Vote:Landing:${artist.name}:Register`,						
            server:"Warner Music France:Site",						
            platform:"Static HTML",						
            devTeam:"Firepit"						
          },							
          category:{							
            primaryCategory:"Warner Music France:Landing Page",						
            pageType:"Landing Page:Vote Redirect"						
          }							
        },								
        content:{								
          artist:"Warner Music France",							
          label:"Warner Music International",							
          sublabel:"Warner Music France"							
        }								
      }																												
                                      
      document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
     } else if (state.login === false && artist?.name) {
      window.digitalData={									
        settings:{								
          reportSuites:"wmg,wmgfr"							
        },								
        page:{								
          pageInfo:{							
            pageName:`Warner Music France:NRJ Awards 2023 Vote:Landing:${artist.name}:Sign In`,						
            server:"Warner Music France:Site",						
            platform:"Static HTML",						
            devTeam:"Firepit"						
          },							
          category:{							
            primaryCategory:"Warner Music France:Landing Page",						
            pageType:"Landing Page:Vote Redirect"						
          }							
        },								
        content:{								
          artist:"Warner Music France",							
          label:"Warner Music International",							
          sublabel:"Warner Music France"							
        }								
      }	

      document.dispatchEvent(new CustomEvent('SPAPageTriggered'));								
     }
  }, [state.login, artist?.name])

  return (
    <main className="inscription sign">
        <img className="closeIcon" onClick={() => setIsOpenInscription(false)} src={closeIcon} alt="" />
        <section className={`signForm ${state.login ? 'signupForm' : 'signInForm'}`}>
            { state.login ? <h1>Se connecter</h1> : <h1>S'inscrire</h1> }
            <div className="toggleSign">
                <button className={`${!state.login ? 'active': ''}`} onClick={() => {
                    openForm()
                    setError('')
                }}>S'inscrire</button>
                <button  className={`${state.login ? 'active': ''}`} onClick={() => {
                    openLogin()
                    setError('')
                }}>Se connecter</button>
            </div>
            <form>
                {state.login && (
                    <>
                        <input type="text" onFocus={e => e.target.classList.remove('error')} onChange={(e)=>setFormInput(e, "email")} value={formData.email} placeholder="Email *" />
                    </>
                )}
                
                {
                  showSpotify && !isInapp.isInApp && <button type="button" data-track="spotify-follow" className={`wmg-button spotify ${state.login ? 'off' : ''}`} data-platform="spotify"><img src={logoSpotify} alt="" /> S'inscrire avec Spotify</button>
                }
                {
                  !showSpotify && !isInapp.isInApp && !state.login && <p className="please">Veuillez compléter les champs suivants pour finaliser votre inscription</p>
                }
                <div ref={formulaireRef} className={`${state.login ? 'off' : 'wmgsdk-content-container'}`} id="formulaire"></div>
                {error.length > 0 && <p className="errorMessage">{error}</p>}
                {state.login && (
                  <>
                  
                  {!isInapp.isInApp && <p className="spotifyConnect">Vous vous êtes inscrits avec Spotify ?<br />Insérez le mail associé à votre compte.</p>}
                    <button type="submit" onClick={e => {
                      e.preventDefault()
                      login()
                    }}>
                        Se connecter
                    </button>
                  </>
                )}
            </form>
        </section>

        <section className="rightPart">
            <img className="logoNMA" src={LogoNMA} alt="" />

            <picture>
                <source srcSet={artist.picture} media="(min-width: 1025px)" />
                <img className="artistePic" src={artist.pictureMobile} alt="" />
            </picture>
            <img className="logoWarner" src={LogoWarner} alt="" />
        </section>
      </main>
  )
}

export default Inscription